:root {
    --button-primary-color: #F43D58;
}

html {
    background-color: #ffffff;
    box-sizing: border-box;
}

body {
    font-family: 'Rosario', sans-serif;
    background-color: #EEEDFA;
}

.body-wrapper {
    overflow-x: hidden;
}

*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
}

.container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}
@media (min-width: 1400px) {
    .container {
        max-width: 1200px;
    }
}

h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 55px;
    color: #223139;
    padding-bottom: 60px;
    padding-top: 120px;
    text-align: center;
}

h2 {
    font-family: 'Rosario';
    font-style: normal;
    font-weight: 700;
    font-size: 43px;
    line-height: 55px;
    color: #223139;
    padding-bottom: 50px;
}

h4 {
    font-family: 'Rosario';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 55px;
text-align: center;
color: #928FAC;
}


p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 35px;
    color: #596570;
}

p:not(:last-child) {
    padding-bottom: 25px;
}

li {
    list-style: none;
}

a {
    text-decoration: none;
}


/* Banner */
.banner{
    background: #FFFFFF;
    box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
    border-radius: 0px 0px 10px 10px;
    padding: 10px 20px 10px 10px;
    display: grid;
    gap: 30px;
    align-items: center; 
    grid-template-columns: 0.8fr 2fr 0.7fr;
    margin-bottom: 25px;
    position: relative;
    z-index: 10;
  }
  .banner .logo-box{
    background: #EAF2F8;
    border-radius: 10px;
  }
  .banner .logo-box a{
    background: url(../image/domainer_logo.svg);
    background-repeat: no-repeat;
    background-position: center center;
    color: transparent;
    display: block;
    font-size: 0;
    padding: 20px 30px;
    height: 138px;
  }
  .banner .banner-title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 15px;
    color: #090909;
  }
  .banner .info{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #7D7F82;
    position: relative;
    margin-left: 15px;
    background-color: transparent;
    padding: 0;
  }
  .banner .info:before{
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    margin-left: -15px;
    border-radius: 10px;
    background: #F27558;
  }
  .banner .domaner-btn{
    background: #F27558;
    transition: all 0.5s ease-in-out;
    border-radius: 10px;
    padding: 23px 5px;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #000000;
  }
  .banner .domaner-btn:hover {
    background-color: rgb(249, 173, 141);
  }
  @media only screen and (max-width: 1090px) {
    .banner .banner-title{
        font-size: 22px;
        margin-bottom: 10px;
    }
    .banner .info{
        font-size: 18px;
        text-align: left!important;
    }
    .banner .domaner-btn{
        font-size: 18px;
    }
    .banner .domaner-btn {
        font-size: 18px;
        line-height: 22px;
        display: table;
        margin: 0 auto;
        padding: 23px 30px;
    }
  }
  @media only screen and (max-width: 940px) {
    .banner{
        grid-template-columns: 1fr;
        padding: 10px;
    }
    .banner .logo-box a{
        background: url(../image/domainer_logo_mob.svg);
        background-repeat: no-repeat;
        background-position: center center;
        height: 66px;
    }
    .banner .banner-title{
        line-height: 24px;
        text-align: center;
    }
    .banner .info{
        font-size: 16px;
        line-height: 20px;
        text-align: center!important;
    }
    .banner .info:before{
        content: none;
    }
  }
  @media only screen and (max-width: 440px) {
    .banner .domaner-btn {
        display: block;
        padding: 23px 0px;
    }
  }


.site-logo {
    background-image: url(../image/logo.svg);
    color: transparent;
    background-repeat: no-repeat;
    font-size: 40px;
    background-size: contain;
}

.header-description {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 35px;
    padding-bottom: 120px;
    text-align: center;
    color: #596570;
}

.nav-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0;
}

.nav ul {
    display: flex;
    align-items: center;
    gap: 80px;
}

.nav ul li a {
    font-weight: 400;
    font-size: 19px;
    line-height: 35px;
    color: #556880;
    position: relative;
    padding: 15px 20px 15px 35px;
    border-radius: 40px;
    transition: all 0.3s ease-in-out;
}


.nav ul li a:hover {
    background-color: #F2F9FF;
    
}

.nav ul li a::before {
    position: absolute;
    content: "";
    width: 5px;
    height: 5px;
    background-color: #9CC9E3;
    border-radius: 20px;
    left: 18px;
    top: 25px;
}

.site-header-inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
}

.hero-img {
    background-image: url(../image/hero-toy.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 12em;
    position: relative;
}



.site-header .container {
    position: relative;
    z-index: 2;
}

.site-header .container.hero-bg::before{ 
    content: "";
    position: absolute;
    width: 1332px;
    height: 1204px;
    background: #DAECFC;
    filter: blur(50px);
    top: 0%;
    opacity: 0.6;
    right: -50%;    
    z-index: -1;
}

.hero-img::before {
    content: "";
    position: absolute;
    background-image: url(../image/circles.png);
    width: 690px;
    height: 690px;
    background-size: contain;
    top: 0;
    right: -100px;
    z-index: -1;
}

.hero-img::after {
    content: "";
    position: absolute;
    width: 400%;
    height: 283px;
    background-color: #B6E3FC;
    z-index: -1;
    border-radius: 40px;
    bottom: 48px;
    left: 100px;
}

.grid-table {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    text-align: center;
    gap: 27px;
    padding: 30px 0;
    position: relative;
}

.grid-table::before {
    position: absolute;
    content: "";
    background-image: url(../image/bg-circles.svg);
    width: 120px;
    height: 100px;
    top: 0px;
    left: -70px;
    z-index: -1;
}

.main {
    position: relative;
    z-index: 10;
}

.grid {
    padding: 20px 0;
    background: #fff;
    border-radius: 40px;
    border: 2px solid #fff;
    transition: all 0.3s ease-in-out;
}

.grid:hover {
    background-color: #EBEAFF;
}

.row {
    display: grid;
    gap: 15px;
}

.grid-table a {
    font-weight: 400;
font-size: 23px;
line-height: 42px;
color: #556880;
}

.what-is {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 70px 0;
    position: relative;
    z-index: 2;
}

.what-is::before {
    content: "";
    position: absolute;
    width: 1332px;
    height: 1200px;
    background: #FFA2E5;
    opacity: 0.1;
    filter: blur(300px);
    left: -700px;
    z-index: 0;
}

.what-is::after {
    position: absolute;
    content: "";
    background-image: url(../image/main-wave.svg);
    width: 100%;
    height: 201px;
    background-repeat: no-repeat;
    background-size: contain;
    bottom: 11px;
    left: -515px;
    z-index: -2;
}

.what-is-content h2 {
    padding-top: 90px;
}

.what-is-img {
    background-image: url(../image/main-toy.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}



.what-is-img::before {
    position: absolute;
    content: "";
    width: 85%;
    height: 130px;
    background-color: #B6E3FC;
    border-radius: 40px;
    bottom: 10px;
    z-index: -1;
}

.what-is-img::after {
    position: absolute;
    content: "nft?";
    background-color: #FFD5FB;
    border-radius: 50%;
    color: #fff;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 800;
    font-size: 45px;
    line-height: 35px;
    width: 125px;
    height: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-25.38deg);
    top: 40px;
}

.leading {
    text-align: center;
    padding: 50px 0;
}

.main-table {
    position: relative;
    z-index: 2;
}

.main-table p {
    font-family: Rosario;
}

.tbody {
    position: relative;
}


.thead-bar,
.tbody-col {
    display: grid;
    grid-template-columns: 20% 15% 15% repeat(4, 1fr);
}

.tbody-col li p {
    line-height: 20px;
}

.thead-bar li {
    align-self: center;
}

.thead-bar li {
    color: #928FAC
}

.thead {
    position: relative;
    padding: 22px 18px;
}

.thead::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    opacity: 0.4;
    border-radius: 40px;
    padding: 25px 0;
    top: 0px;
    left: 0;
    z-index: -1;
}

.tr {
    background: white;
    margin-top: 20px;
    border-radius: 40px;
    padding: 17px 20px;
    position: relative;
}

.funko::before {
    position: absolute;
    content: "";
    width: 85px;
    height: 85px;
    background-color: #FFD5FB;
    z-index: -1;
    left: -44px;
    top: -24px;
    border-radius: 50%;
}

.main-table::before {
    position: absolute;
    content: "";
    background-image: url(../image/table-circles.svg);
    width: 500px;
    height: 500px;
    z-index: -1;
    right: -200px;
    background-size: contain;
    background-repeat: no-repeat;
    top: -81px;
}

.toys-r-us::before {
    position: absolute;
    content: "";
    width: 47px;
    height: 47px;
    right: -22px;
    z-index: -1;
    background-color: #FFD5FB;
    border-radius: 50%;
}

.thead li:first-of-type {
    text-align: left;
    padding-left: 20px;
}

.tbody-col li:first-of-type {
    display: flex;
    align-items: center;
    justify-content: left;
    padding-left: 20px;
}
.tbody-col li:first-of-type a {
    font-weight: 700;
font-size: 22px;
line-height: 30px;
color: #313E50;
text-align: left;
}

.tbody-col li {
    align-self: center;
}

.cta {
    background-color: #F43D58;
    border-radius: 40px;
    font-family: 'Rosario';
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 25px;
    color: #FFFFFF;
    display: flex;
    width: 100%;
    justify-content: center;
    transition: all 0.3s ease-in-out;
    padding: 17px 0;
    
}

.cta:hover {
    background-color: #54BEFB;
}

.middle p {
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    color: #556880;
}

.yes {
    position: relative;
}

.yes p,
.blockchain p,
.no p {
    color: transparent !important;
}

.blockchain p,
.blockchain {
    position: relative;
}

.tr:nth-of-type(1) .blockchain p::before,
.tr:nth-of-type(11) .blockchain p::before,
.tr:nth-of-type(12) .blockchain p::before,
.tr:nth-of-type(13) .blockchain p::before {
    position: absolute;
    content: "";
    background-image: url(../image/wax.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: 50px;
    height: 20px;
    top: 10px;
    left: calc(50% - 25px);
}

.tr:nth-of-type(2) .blockchain p::before,
.tr:nth-of-type(3) .blockchain p::before,
.tr:nth-of-type(4) .blockchain p::before,
.tr:nth-of-type(9) .blockchain p::before,
.tr:nth-of-type(10) .blockchain p::before{
    position: absolute;
    content: "";
    background-image: url(../image/Ethereum.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: 34px;
    height: 34px;
    left: calc(50% - 17px);
}

.tr:nth-of-type(5) .blockchain p::before {
    position: absolute;
    content: "";
    background-image: url(../image/Polygon.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: 35px;
    height: 35px;
    left: calc(50% - 17px);
}
.tr:nth-of-type(6) .blockchain p::before {
    position: absolute;
    content: "";
    background-image: url(../image/palm-eth.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: 35px;
    height: 35px;
    left: calc(50% - 17px);
}

.tr:nth-of-type(7) .blockchain p::before,
.tr:nth-of-type(8) .blockchain p::before {
    position: absolute;
    content: "";
    background-image: url(../image/immutable-x.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: 35px;
    height: 35px;
    left: calc(50% - 17px);
    top: 0px;
}

.blockchain:hover::before {
    content: 'WAX';
    position: absolute;
    color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -40px;
    background-image: url(../image/blockchain-bg.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    padding-bottom: 5px;
}

.tr:nth-of-type(2) .blockchain:hover::before,
.tr:nth-of-type(3) .blockchain:hover::before,
.tr:nth-of-type(4) .blockchain:hover::before,
.tr:nth-of-type(9) .blockchain:hover::before,
.tr:nth-of-type(10) .blockchain:hover::before {
    content: "Ethereum";
}

.tr:nth-of-type(5) .blockchain:hover::before {
    content: "Polygon";
}

.tr:nth-of-type(6) .blockchain:hover::before {
    content: "Palm";
}

.tr:nth-of-type(7) .blockchain:hover::before,
.tr:nth-of-type(8) .blockchain:hover::before {
    content: "Immutable X - Ethereum";
    top: -54px;
    padding-bottom: 11px;
    width: 200%;
    left: -50%;
    background-image: url(../image/immutable-bg.png);
}

.yes::before {
    position: absolute;
    content: "";
    background-image: url(../image/checkmark.svg);
    background-size: contain;
    width: 26px;
    height: 19px;
    background-repeat: no-repeat;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 0px;
}

.blockchain p {
    height: 40px;
}

.yes span {
    padding-left: 15px;
    color: #556880;
}

.yes p {
    padding-bottom: 0;
}

.no {
    position: relative;
    color: transparent;
}

.no::before {
    position: absolute;
    content: "";
    background-image: url(../image/no.png);
    background-size: contain;
    width: 26px;
    height: 19px;
    background-repeat: no-repeat;
    top: 0px;
    margin: 0 auto;
    left: 0;
    right: 0;
}

.selected {
    background-color: #EBEAFF;
    border: 4px solid #fff;
}

.irl {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 66px 0;
}

.irl-img {
    background-image: url(../image/nft-toys-airplane.png);
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    background-position: bottom;
}

.irl-img::before {
    position: absolute;
    content: "";
    width: 80%;
    height: 180px;
    background-color: #B6E3FC;
    border-radius: 40px;
    bottom: 0;
    z-index: -1;
}

.irl-content h2 {
    padding-top: 50px;
}


.where {
    padding: 50px 0;
}

.where p {
    padding-bottom: 40px;
}

.most-famous-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 33px;
    padding-top: 25px;
}

.most-famous-grid li {
    background-color: #fff;
    border-radius: 40px;
    position: relative;
    transition: all 0.3s ease-in-out;
}

.most-famous-grid li::after {
    position: absolute;
    content: "";
    background-image: url(../image/arrow-right.svg);
    width: 15px;
    height: 24px;
    background-size: contain;
    background-repeat: no-repeat;
    top: 22px;
    right: 22px;

}

.most-famous-grid li a {
    color: transparent;
    background-size: 150px;
    background-repeat: no-repeat;
    background-position: 20px;
    font-size: 12px;
    width: 100%;
    display: flex;
    padding: 25px;
    border: 1px solid #fff;
    border-radius: 40px;
    transition: all 0.3s ease-in-out;
}

.most-famous-grid li:hover::after {
    filter: invert(78%) sepia(36%) saturate(4360%) hue-rotate(173deg) brightness(98%) contrast(80%);
}

.most-famous-grid li a:hover {
    border: 1px solid #54BEFB;
}

.opensea {
    background-image: url(../image/opensea.png);
}

.nba-topshot {
    background-image: url(../image/nba-topshop.png);
}

.rarible {
    background-image: url(../image/Rarible.png);
}

.superrare {
    background-image: url(../image/SuperRare.png);
    background-position: 20px !important;
}

.about-us {
    background-color: #CECCE5;
    border-radius: 40px;
    position: relative;
    padding: 60px 50% 0px 60px;
    margin-top: 150px;
    margin-bottom: 140px;
}

.about-us::before {
    position: absolute;
    content: "";
    background-image: url(../image/about-us-circle.png);
    z-index: -1;
    width: 817px;
    height: 831px;
    background-size: contain;
    background-repeat: no-repeat;
    right: -176px;
    top: -295px;
}

.about-us::after {
    position: absolute;
    content: "";
    background-image: url(../image/about-us-toys.png);
    width: 466px;
    height: 430px;
    background-size: contain;
    background-repeat: no-repeat;
    right: 97px;
    top: -98px;
}

.about-us p {
    color: #fff;
    padding-bottom: 0;
}

.about-us h2 {
    padding-bottom: 30px;
    color: #fff;
}

.contact-cta {
    position: relative;
    font-family: 'Rosario';
font-style: normal;
font-weight: 700;
font-size: 25px;
line-height: 25px;
color: #FFFFFF;
border-radius: 60px;
background-color: #F43D58;
border: 14px solid #EEEDFA;
padding: 25px 40px;
bottom: -40px;
transition: all 0.3s ease-in-out;
display: block;
width: fit-content;

}

.contact-cta:hover {
    background-color: #54BEFB !important;
}

footer .site-logo {
    display: none;
}

footer {
    background-color: #223139;
}
footer .container {
    display: flex;
align-items: center;
text-align: center;
justify-content: center;
padding: 10px 0;
}
footer span {
    font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;

color: #fff;
}

/* READ MORE  */
.m-visible {
    display: initial;
}
.m-slide-down {
    height: 0;
    overflow: hidden;
    -moz-transition: height 0.5s;
    -ms-transition: height 0.5s;
    -o-transition: height 0.5s;
    -webkit-transition: height 0.5s;
    transition: height 0.5s;
  }
  .m-hidden,
  .mobile-detector {
    display: none;
  }
  .m-visible {
    display: initial;
  }
  .m-hidden {
    display: initial;
  }
  .m-slide-down-measure p {
    margin: 0 !important;
  }
  .m-readmore-btn {
    background: transparent;
    border: none;
    text-transform: uppercase;
    font-size: 0;
    font-weight: 500;
    color: white;
    width: 100%;
    margin: 15px auto 50px;
    position: relative;
  }
  
  .m-readmore-btn::before {
    cursor: pointer;
    display: inline-block;
    width: 25px;
    height: 25px;
    vertical-align: top;
    color: rgb(240, 240, 240);
    font-size: 24px !important;
  }
  
  .m-readmore-btn.read-more-2::before {
    content: "";
    position: absolute;
    background-image: url(../image/load-more.svg);
    width: 50px;
    height: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    top: -42px;
  }
  .m-readmore-btn.collapse-2::before {
    content: "\2212";
  }
  
  .m-readmore-btn * {
    pointer-events: none;
  }
  .m-visible {
    display: initial;
  }
  
  .m-hidden {
      display: none;
  }
  .m-readmore-btn.read-more-3::before{
    content: "";
    position: absolute;
    background-image: url(http://localhost:7052/image/load-more.svg);
    width: 50px;
    height: 50px;
    background-size: contain;
    background-repeat: no-repeat;
    bottom: 0px;
    right: -180px;
  }

.load-more {
background-color: #404C52;
border-radius: 11px;
display: flex;
justify-content: center;
align-items: center;
height: 33px;
color: #fff;
text-transform: uppercase;
}

.read-more-2 {
position: relative;
}


.load-more .m-visible {
display: initial !important;
}


/* Navigation */
  


@media (min-width: 1300px){
    a.button {
        min-width: 170px;
    }
}
@media only screen and (max-width: 1067px){


    .site-header .cta-btn {
        display: none;
    }
    .site-header .site-nav{
        z-index: auto;
        position: relative;
    }
    .nav-visible .site-logo{
        padding-top: 10px;
    }
    .site-nav .icon{
        border-top: 3.5px solid var(--button-primary-color);
        height: 20px;
        width: 30px;
        box-sizing: border-box;
        position: absolute;
        z-index: 30;
        right: 0px;
        top: -15px;
        cursor: pointer;
        -webkit-transition: all 0.3s ease-in;
        -moz-transition: all 0.3s ease-in;
        -khtml-transition: all 0.3s ease-in;
        -o-transition: all 0.3s ease-in;
        transition: all 0.3s ease-in;
    }
    .site-nav .icon:before {
        content: "";
        display: block;
        position: absolute;
        height: 3.5px;
        width: 30px;
        right: 0;
        background: var(--button-primary-color);
        top: 5px;
        -webkit-transition: all 0.3s ease-in;
        -moz-transition: all 0.3s ease-in;
        -khtml-transition: all 0.3s ease-in;
        -o-transition: all 0.3s ease-in;
        transition: all 0.3s ease-in;
    }
    .site-nav .icon:after {
        content: "";
        display: block;
        position: absolute;
        height: 3.5px;
        width: 30px;
        right: 0;
        background: var(--button-primary-color);
        bottom: -1px;
        -webkit-transition: all 0.3s ease-in;
        -moz-transition: all 0.3s ease-in;
        -khtml-transition: all 0.3s ease-in;
        -o-transition: all 0.3s ease-in;
        transition: all 0.3s ease-in;
    }
    .nav-visible .site-nav .icon{
        border: transparent;
        top: 30px;
        right: 20px;
    }
    .nav-visible .site-nav .icon:before {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -khtml-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        width: 33px;
        right: -2px;
    }
    .nav-visible .site-nav .icon:after {
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -khtml-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
        width: 33px;
        right: -1px;
        top: 6px;
    }
    .nav-visible .site-nav{
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        overflow-x: hidden;
        overflow-y: auto;
        text-align: left;
        z-index: 10;
        background: #EEEDFA;
    }

    .nav-visible .nav::before {
        content: none;
    }
    .site-header.nav-visible .container{
        display: flow-root;
    }
    .site-nav>ul{
        opacity: 0;
        visibility: hidden;
        display: none;
    }
    .nav-visible .site-nav>ul {
        display: grid;
        justify-content: center;
        align-items: center;
        opacity: 1;
        visibility: visible;
        position: fixed;
        width: 100%;
        height: 50%;
        top: 70px;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-x: hidden;
        overflow-y: auto;
        text-align: center;
        margin: 0 auto;
    }
    .nav-visible .site-nav>ul>li{
      margin-bottom: 0px; 
      margin-right: 0px;
      padding: 30px 0;
    }

    .nav-visible ul li a:hover {
        /* background-color: transparent; */
    }

    .nav-visible ul li a {
        position: relative;
    }

    .nav-visible ul li a::before {
        content: "";
        position: absolute;
        width: 120%;
        height: 100%;
        top: 0;
        background: red !important;
    }

    .nav-visible .site-nav {
        /* position: relative; */
    }

    .nav-visible .site-nav::before {
        position: absolute;
    content: "";
    width: 700px;
    height: 80vh;
    background-color: #DAECFC;
    filter: blur(150px);
    top: 10vh;
    right: -479px;
    }

    .nav ul li a:hover::before {
        opacity: 0;
    }

    .btn-group li {
        position: relative;
    }
    .nav-visible .site-nav>ul>li:hover a{
      color: #54BEFB !important;
    }
    .nav-visible .site-nav>ul>li:nth-of-type(2) {
      margin-left: 0px;
  }
    .nav-visible .site-nav>ul>li:before{
      content: none;
    }
    .nav-visible .site-nav>ul>li a{
        font-weight: 700;
    font-size: 23px;
    line-height: 35px;
    text-align: center;
    color: #3A3945;
    padding: 25px;
    }

    .nav-visible ul li a {
        padding: 0
    }

    .nav-visible ul li a::before {
        content: none;
    }

    .nav-visible  .btn-group{
        box-shadow: none;
    }
    .nav-visible .site-nav>ul>li a.btn ,  .nav-visible .btn-group li:not(:last-child):not(.dropdown-toggle) .btn, .nav-visible .btn-group li:not(:first-child) .btn{
        border: none;
        background-color: transparent;
    }
}

.nav-visible .main{
    z-index: 0;
}

.mobile-h2 {
    display: none;
}

.grid-table.mobile {
    display: none !important;
}

@media only screen and (max-width: 1200px) {
    .nav ul {
        gap: 0;
    }
    .about-us::after {
        top: -59px;
        width: 415px;
    height: 390px;
    }
    .hero-img {
        margin-left: 8em;
        background-position: center;
    }
}


@media only screen and (max-width: 962px) {
    .hero-img {
        margin-left: 2em;
    }

    .what-is-img {
        background-size: 250px;
    background-repeat: no-repeat;
    background-position: 66px 190px;
    }

    .what-is-img::after {
        top: 90px;
    }

    .what-is-content h2 {
        padding-top: 0;
    }
    .tbody-col li:first-of-type a {
        font-size: 20px;
    }

    .thead li:first-of-type,
    .tbody-col li:first-of-type {
        padding-left: 20px;
    }

    .about-us::after {
        top: 50px;
    width: 360px;
    height: 390px;
    right: 10px;
    }

    .most-famous-grid {
        grid-template-columns: 1fr;
        gap: 15px;
    }

    .about-us {
        margin-top: 100px;
    }

    .about-us::before {
        top: -255px;
    }
    .what-is:before{
        content: none;
    }
}

@media only screen and (max-width: 768px) {
    h1 {
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 40px;
        
        padding-top: 29px;
        padding-bottom: 30px;
    }

    h2 {
        font-weight: 700;
font-size: 30px;
line-height: 40px;
padding-bottom: 15px;
    }

    p {
        font-size: 17px;
        line-height: 30px;
    }

    .container {
        padding: 0 22px;
    }

    .site-header-inner {
        grid-template-columns: 1fr;
    }

    .hero-content h1 {
        text-align: center;
    }

    .hero-img::after {
        content: none;
    }

    .hero-img::before {
        top: -400px;
    right: -381px;
    }

    .header-description {
        padding-bottom: 35px;
    }

    .grid-table {
        grid-template-columns: 1fr;
        gap: 15px;
        display: none;
    }

    .grid-table.mobile {
        display: grid !important;
        gap: 7.5px;
    }

    .m-slide-down-measure .row {
        padding-bottom: 15px;
    }

    .what-is,
    .irl {
        display: flex;
        flex-direction: column;
    }

    .what-is-img-mobile {
        background-image: url(../image/main-toy.png);
        width: 100%;
        height: 350px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        position: relative;
    }

    .what-is-img::before {
        content: none;
    }

    h2 {
        padding-bottom: 25px;
        text-align: center;
    }

    .what-is-img-mobile::before {
        position: absolute;
    content: "";
    width: 85%;
    height: 130px;
    background-color: #B6E3FC;
    border-radius: 40px;
    bottom: 10px;
    z-index: -1;
    }


    .what-is-img::after {
        width: 85px;
        height: 85px;
        font-size: 25px;
        top: 135px;
    }

    .mobile-h2 {
        display: initial;
        text-align: center;
    }
    .desktop {
        display: none;
    }

    .what-is p {
        text-align: center;
        padding-top: 20px;
    }

    .thead {
        display: none;
    }

    .tbody-col {
        display: inline-block;
    width: 100%;
    }

    .tbody-col li:first-of-type {
        justify-content: center;
        padding: 20px 0;
        border-bottom: 1px solid #EEEDFA;
        margin-bottom: 25px;
    }
    .middle,
    .yes,
    .blockchain {
        text-align: right;
        padding-bottom: 15px;
        font-weight: 400;
    font-size: 15px;
    line-height: 29px;
    color: #556880;
    }

    

    .yes::before {
        right: 0;
        left: auto;
    }

    .blockchain p::before {
        left: auto !important;
        right: 0
    }

    .tbody-col li {
        position: relative;
        text-align: right;
        padding-bottom: 15px;
    }

    .tbody-col li:nth-of-type(2)::before {
        position: absolute;
        content: "NFT Collection Name";
        left: 0;
        font-weight: 400;
        font-size: 13px;
        line-height: 30px;
        color: #928FAC;
    }

    .tbody-col li:nth-of-type(3)::before {
        position: absolute;
        content: "Partnership";
        left: 0;
        font-weight: 400;
        font-size: 13px;
        line-height: 30px;
        color: #928FAC;
    }

    .tbody-col li:nth-of-type(4)::after {
        position: absolute;
        content: "Figure in the NFT Pack";
        left: 0;
        font-weight: 400;
        font-size: 13px;
        line-height: 30px;
        color: #928FAC;
        top: 0;
    }
    .tbody-col li:nth-of-type(5)::before {
        position: absolute;
        content: "Genre";
        left: 0;
        font-weight: 400;
        font-size: 13px;
        line-height: 30px;
        color: #928FAC;
    }
    .tbody-col li:nth-of-type(6)::before {
        position: absolute;
        content: "Blockchain";
        left: 0;
        font-weight: 400;
        font-size: 13px;
        line-height: 30px;
        color: #928FAC;
    }

    .blockchain {
        margin-bottom: 15px;
    }

    .no::before {
        right: 0;
        left: auto;
    }

    .cta {
        padding: 16px 0;
        width: 75%;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }
    
    .irl-img {
        width: 100%;
        height: 300px;
        background-position: center;
        margin-bottom: 50px;
    }

    .irl {
        padding: 20px 0;
    }

    .irl-img::before {
        height: 105px;
        left: 40px;
        border-radius: 20px;
        bottom: -20px;
    }

    .irl-content p {
        text-align: center;
    }

    .where {
        text-align: center;
    }
    
    .most-famous-grid {
        grid-template-columns: 1fr;
        gap: 15px;
    }
    .most-famous-marketplace h4 {
        display: none;
    }

    .about-us {
        padding: 258px 25px 0px 25px;
        padding: 25px;
        text-align: center;
    }

    .about-us h2 {
        padding-bottom: 300px;
    }

    .about-us::after {
        width: 300px;
        height: 300px;
        right: calc(50% + -136px);
    top: 75px;
    }

    .contact-cta {
        bottom: -66px;
    }

    .m-readmore-btn.read-more-2::before {
        left: calc(50% - 25px);
    }
}

@media only screen and (max-width: 392px) {
    .contact-cta:hover::before {
        width: 100%;
        line-height: 12px;
        left: 0;
        padding-top: 23px;
    }
    .contact-cta {
        padding: 24px 28px;
        font-size: 22px;
    }
}
